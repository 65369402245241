// src/components/Book.js
import React, { useState, useEffect } from 'react';
import Page from './Page';
import page1 from '../assets/images/page1.jpg';
import page2 from '../assets/images/page2.jpg';
import page3 from '../assets/images/page3.jpg';
import page4 from '../assets/images/page4.jpg';
import coverImage from '../assets/images/cover-image.jpg';

const pages = [page1, page2, page3, page4];

const Book = () => {
  const [currentPage, setCurrentPage] = useState(-1);
  const [animation, setAnimation] = useState('');

  const nextPage = () => {
    if (currentPage < pages.length - 2) {
      setAnimation('page-turn');
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 2);
      }, 600);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setAnimation('page-turn-back');
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage - 2);
      }, 600);
    } else {
      setCurrentPage(-1); // Go back to the cover
    }
  };

  useEffect(() => {
    setAnimation('');
  }, [currentPage]);

  if (currentPage === -1) {
    return (
      <div className="front-cover">
        <button className="arrow-button" onClick={prevPage} disabled={true}>&larr;</button>
        <div className="cover-container">
          <h1 className="cover-title">BOOK OF AESTHETICS</h1>
          <img src={coverImage} alt="Cover" className="cover-image" />
        </div>
        <button className="arrow-button" onClick={() => setCurrentPage(0)}>&rarr;</button>
      </div>
    );
  }

  return (
    <div className="book">
      <button className="arrow-button" onClick={prevPage} disabled={currentPage === -1}>&larr;</button>
      <div className="book-container">
        <Page image={pages[currentPage]} className={animation} />
        {currentPage + 1 < pages.length && <Page image={pages[currentPage + 1]} className={animation} />}
        <div className="page-separator"></div>
      </div>
      <button className="arrow-button" onClick={nextPage} disabled={currentPage >= pages.length - 2}>&rarr;</button>
    </div>
  );
};

export default Book;
