// src/components/Navbar.js
import React from 'react';

const Navbar = () => {
  return (
    <nav>
      <h1>Book of Aesthetics</h1>
      <ul>
        <li><a href="https://t.me/boaesthetics" target="_blank" rel="noopener noreferrer">Telegram</a></li>
        <li><a href="" target="_blank" rel="noopener noreferrer">Pump.fun</a></li>
        <li><a href="https://x.com/BOAesSOL" target="_blank" rel="noopener noreferrer">Twitter</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
