// src/components/Page.js
import React from 'react';

const Page = ({ image, className }) => {
  return (
    <div className={`page ${className}`}>
      <img src={image} alt="Book Page" />
    </div>
  );
};

export default Page;
